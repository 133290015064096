.service-page{
    width: 100%;
    min-height: 100vh;
}
.service-page-landing-section{
    width: 100%;
    min-height: 100vh;
    padding: 3rem 2rem;
    padding-top: 5rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;
    position: relative;
    /* background-color: rgb(246, 238, 255); */
    background: linear-gradient(to bottom , white , rgb(246, 238, 255));
}
/* .service-page-landing-section::before{
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 80%;
    height: 100%;
    border-bottom-right-radius: 7000%;
    background-color: white;
} */
.service-page-landing-section-left{
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}
.service-page-landing-section-left h2 span{
    /* color: var(--blue); */
    background: -webkit-linear-gradient(var(--blue), var(--green));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.service-page-landing-section-right img{
    width: 350px;
    position: relative;
}


.service-page-second-section{
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}
.service-page-second-section::before{
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background:linear-gradient(to bottom , rgb(246, 238, 255) , white );
}


@media screen and (max-width:500px) {
    .service-page-landing-section-right img{
        width: 250px;
    }
    .service-page-landing-section-left{
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .service-page-landing-section{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        min-height: auto;
        height: 80vh;
    }
    .service-page-second-section{
        padding-top: 1rem;
    }
}
