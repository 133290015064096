.testimonial-card{
    width: 400px;
    background-color: white;
    padding: 1rem 1rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 9px 0px;
    border-radius: 6px;
    display: flex;
    gap: 2rem;
    align-items: center;
}
.testimonial-card img{
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
}
.testimonial-card-content{
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}
.testimonial-card-content p{
    font-size: 10px;
}

@media screen and (max-width:500px) {
    .testimonial-card{
        width: 350px;
    }
}

@media screen and (max-width:400px) {
    .testimonial-card{
        width: 100%;
    }
    .testimonial-card img{
        width: 40px;
        height: 40px;
    }
}