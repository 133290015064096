.footer{
    padding: 2rem 2rem;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    padding-top: 5rem;
    gap: 2rem;
}
.footer-top{
    display: flex;
    justify-content: space-evenly;
    gap: 2rem;
}
.footer-column{
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    max-width: 300px;
}
#fcolumn1 img{
    width: 100px;
}
#fcolumn1 h4{
    color: var(--green);
}
#fcolumn1 h4 span{
    color: var(--blue);
}
.footer-column p{
    display: flex;
    align-items: center;
    gap: 0.6rem;
    color: gainsboro;
    font-size: 14px;
}
.footer-column .icons{
    font-size: 14px;
}
#fcolumn2 h4:nth-child(4){
    margin-top: 1rem;
}
.social-media-row{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.social-media-row .social-media-icons{
    color: white;
}
.footer-bottom{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 2rem;
}
.footer-bottom a{
    color: var(--blue);
}

@media screen and (max-width:750px){
    .footer-top{
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
    #fcolumn3{
       max-width: 100%; 
       grid-column-start: 1;
       grid-column-end: 3;
    }
}

@media screen and (max-width:400px){
    .footer-top{
        display: flex;
        /* flex-direction: column; */
        flex-wrap: wrap;
        gap: 2rem;
        align-items: center;
        justify-content: center;
    }
    .footer-column{
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}