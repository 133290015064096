.testimonial-section{
    width: 100%;
    padding: 4rem 2rem;
    display: flex;
    justify-content: space-evenly;
    gap: 2rem;
    flex-wrap: wrap;
}
.testimonial-section-left h3 span{
    color: var(--blue);
}
.testimonial-section-left{
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
}
.testimonial-section-right{
    height: 300px;
    padding: 1rem 1rem;
    /* border: 1px solid red; */
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.testimonial-section-right::-webkit-scrollbar {
    width: 5px;
  }
  .testimonial-section-right::-webkit-scrollbar-thumb {
    background: var(--green); 
    border-radius: 10px;
  }
  
  @media screen and (max-width:900px) {
    .testimonial-section-left{
        max-width: none;
        width: 100%;
    }
  }