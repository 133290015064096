.landing-section{
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    gap: 0.7rem;
    color: white;
}
.landing-section::before{
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.525);
    z-index: 9;
}
.landing-section video{
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.landing-section img{
    position: relative;
    width: 80px;
    z-index: 10;
}
.landing-section h4{
    z-index: 10;
}
.landing-section h1{
    z-index: 10;
}
.landing-section h1 span{
  /* font-family: "IBM Plex Sans", sans-serif; */
  color: var(--green);
}
.landing-section p{
    z-index: 10;
    max-width: 700px;
}

/* HOMEPAGE LANDING SECTION */

.homepage-landing-section{
    width: 100%;
    /* min-height: 100vh; */
    /* background: url("../../images/landing1.jpg");
    background-size: cover;
    background-position: center; */
    /* background-attachment: fixed; */
    padding: 3rem 2rem;
    /* padding-top: 5rem; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
    /* background-color: gainsboro; */
}
.homepage-landing-section-right img{
    width: 400px;
}
.homepage-landing-section-left{
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.homepage-landing-section-left .btn-a{
    width: 150px;
}
.homepage-landing-section-left h2 span{
    font-family: "IBM Plex Sans", sans-serif;
    color: var(--blue);
}
.wrapper {
    display: inline-flex;
  }
  .wrapper .dynamic-txts {
    /* margin-left: 15px; */
    height: 60px;
    line-height: 60px;
    overflow: hidden;
  }
  .dynamic-txts li {
    color: var(--blue);
    list-style: none;
    font-size: 40px;
    position: relative;
    top: 0;
    animation: slide 6s steps(4) infinite;
}
@keyframes slide {
    100% {
        top: -240px;
    }
}
.dynamic-txts li span {
    position: relative;
    font-family: "IBM Plex Sans", sans-serif;
  }
  .dynamic-txts li span::after {
    content: "";
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background: white;
    border-left: 2px solid var(--blue);
    animation: typing 1.5s steps(10) infinite;
  }
  @keyframes typing {
    100% {
      left: 100%;
      margin: 0 -35px 0 35px;
    }
  }

/* OUR SERVICES */
.our-services{
    padding: 4rem 2rem;
    background-color: rgb(245, 250, 255);
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.our-services-top{
    text-align: center;
}
.our-services-top h3 span{
    color: var(--blue);
    position: relative;
}
.our-services-top h3 span::before{
    content: "";
    position: absolute;
    bottom: -5%;
    left: 0%;
    width: 80%;
    height: 4px;
    border-radius: 20px;
    background-color: var(--blue);
}
.our-services-middle{
    display: flex;
    justify-content: space-evenly;
    gap: 2rem;
    flex-wrap: wrap;
}
.static-service-card{
    width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.7rem;
    background-color: white;
    padding: 2rem 2rem;
    border-radius: 6px;
    min-height: 300px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.static-service-card img{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
}
.static-service-card p{
    font-size: 12px;
}
.our-services-bottom{
    display: flex;
    justify-content: center;
    align-items: center;
}


/* ABOUT SECTION */
.about-section{
    padding: 4rem 2rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;
}
.about-section-left{
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.about-section-right img{
    width: 400px;
}
.about-section-left .btn-a{
    width: 200px;
}




@media screen and (max-width:900px) {
    .homepage-landing-section{
        flex-wrap: wrap;
    }
    .homepage-landing-section-left{
        max-width: none;
        width: 100%;
    }
    .about-section-left{
        max-width: none;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}


@media screen and (max-width:750px) {
    .landing-section p{
        padding: 0 3rem;
    }
}

@media screen and (max-width:650px) {
    .landing-section{
        height: 70vh;
    }
    .static-service-card{
        width: 200px;
        gap: 0.7rem;
        padding: 2rem 1rem;
        min-height: 220px;
    }
    .static-service-card img{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
    }
    .static-service-card p{
        font-size: 10px;
    }
    .static-service-card h4{
        font-size: 12px;
    }
}

@media screen and (max-width:550px) {
    .homepage-landing-section-right img{
        width: 250px;
    }
    .homepage-landing-section{
        min-height: none;
        height: auto;
    }
}

@media screen and (max-width:500px) {
    .about-section-right img{
        width: 300px;
    }
    .static-service-card{
        width: 150px;
        gap: 0.7rem;
        padding: 1rem 0.5rem;
        min-height: 180px;
    }
    .our-services-middle{
        gap: 1rem;
    }
    .static-service-card img{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
    }
    .static-service-card p{
        font-size: 8px;
    }
    .static-service-card h4{
        font-size: 10px;
    }
}

@media screen and (max-width:400px) {
    .wrapper .dynamic-txts {
        /* margin-left: 15px; */
        height: 40px;
        line-height: 40px;
        overflow: hidden;
      }
      .dynamic-txts li {
        color: var(--blue);
        list-style: none;
        font-size: 30px;
        position: relative;
        top: 0;
        animation: slide 6s steps(4) infinite;
    }
    @keyframes slide {
        100% {
            top: -160px;
        }
    }
      .dynamic-txts li span::after {
        content: "";
        position: absolute;
        left: 0;
        height: 100%;
        width: 100%;
        background: white;
        border-left: 2px solid var(--blue);
        animation: typing 1.5s steps(10) infinite;
      }
      @keyframes typing {
        100% {
          left: 100%;
          margin: 0 -35px 0 35px;
        }
      }
      .our-services{
        padding: 3rem 0.5rem;
      }
      .our-services-middle{
        gap: 0.5rem;
      }
}