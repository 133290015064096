.service-card{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
    position: relative;
    /* flex-wrap: wrap; */
}
.service-card:nth-child(even){
    flex-direction: row-reverse;
}
.service-card img{
    width: 300px;
    height: 270px;
    border-radius: 6px;
    object-fit: cover;
}
.service-card-content{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 600px;
}
.service-card-content h4{
    font-size: 1.8rem;
    color: var(--blue);
}

@media screen and (max-width:750px) {
    .service-card{
        flex-wrap: wrap;
    }
}

@media screen and (max-width:500px) {
    .service-card img{
        width: 200px;
        height: 200px;
    }
    .service-card-content h4{
        font-size: 1.3rem;
    }
}