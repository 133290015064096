.error-page{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    justify-content: center;
    color: var(--blue);
    align-items: center;
}
.error-page img{
    width: 300px;
}