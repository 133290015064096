.navbar{
    padding: 1rem 2rem;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 0%;
    left: 0%;
    /* backdrop-filter: blur(16px); */
    z-index: 999;
    /* background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    background-color: transparent;
    transition: 0.6s;
}
.navbar-center img{
    width: 120px;
}
.navbar-left , .navbar-right{
    display: flex;
    gap: 2rem;
    align-items: center;
}
.nav-items .link-a{
    position: relative;
    transition: 0.6s;
    /* color: white; */
}
.nav-items .link-a:hover{
    color: var(--blue);
}
.nav-items .link-a::before{
    content: "";
    position: absolute;
    bottom: -5%;
    left: 0%;
    width: 0%;
    height: 2px;
    background-color: var(--blue);
    border-radius:20px;
    transition: 0.6s;
}
.nav-items .link-a:hover::before{
    width: 100%;
}
.navbar-right .menu-icon{
    color: black;
    cursor: pointer;
}
.navbar-right{
    display: none;
}

.side-menu{
    width: 80vw;
    height: 100%;
    position: fixed;
    top: 0%;
    right: 0%;
    background-color: white;
    z-index: 1000;
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}
.side-menu img{
    width: 80px;
}
.side-menu .close-icon{
    cursor: pointer;
}

.whatsapp-box{
    position: fixed;
    bottom: 3%;
    right: 3%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #25D366;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 1111;
}
.whatsapp-box .whatsapp-icon{
    color: white;
    font-size: 26px;
}

@media screen and (max-width:750px) {
    .navbar-right{
        display: flex;
    }
    .navbar-left{
        display: none;
    }
}

@media screen and (max-width:500px) {
    .navbar-center img{
        width: 80px;
    }
    .side-menu img{
        width: 60px;
    }
    .side-menu .btn-a{
        font-size: 12px;
    }
}