
.listed-query-card{
    padding: 1.5rem 1.5rem;
    width: 270px;
    background-color: white;
    border-radius: 6px;
    box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    padding-bottom: 3rem;
  }
  .listed-query-card-bottom p{
    font-size: 12px;
  }
  .listed-query-card .delete-icon{
    position: absolute;
    bottom: 4%;
    left: 8%;
  }