.dashboard{
    width: 100%;
    min-height: 100vh;
}
.dashboard-landing-section{
    width: 100%;
    min-height: 100vh;
    background-color: black;
    padding: 3rem 2rem;
    /* padding-top: 9rem; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
    color: white;
}
.dashboard-landing-section-left{
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.dashboard-landing-section-left p{
    font-size: 12px;
}
.dashboard-landing-section-left .btn-a{
    width: 200px;
    background-color: var(--blue);
}
.dashboard-landing-section-left h2{
    color: var(--green);
    font-size: 3.5rem;
}
.dashboard-landing-section-right img{
    width: 450px;
}
.manage-service-section{
    padding: 5rem 2rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
}
.manage-service-section-left{
    position: relative;
}
.service-form{
    padding: 2rem 2rem;
    box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    border-radius: 6px;
}
.service-form .input-field{
    box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
    border-radius: 6px;
}

.manage-service-section-right{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.manage-service-section-right h3{
    color: rgb(167, 166, 166);
    padding-left: 2rem;
}
.manage-service-section-right .scrollable-section{
    height: 400px;
    width: 500px;
    overflow-y: auto;
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.manage-service-section-right .scrollable-section::-webkit-scrollbar {
    width: 5px;
  }
.manage-service-section-right .scrollable-section::-webkit-scrollbar-thumb {
    background: rgb(194, 194, 194); 
    border-radius: 10px;
  }


  .manage-query-section{
    padding: 5rem 2rem;
    background-color: rgb(228, 239, 250);
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .manage-query-section h3{
    color: rgb(255, 255, 255);
  }
  .manage-query-section h3 span{
    color: var(--blue);
  }
  .manage-query-section-bottom{
    display: flex;
    justify-content: space-evenly;
    gap: 2rem;
    align-items: stretch;
    flex-wrap: wrap;
  }


