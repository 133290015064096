.about-page{
    width: 100%;
    min-height: 100vh;
}
.about-page-landing-section{
    width: 100%;
    min-height: 100vh;
    background-color: rgb(248, 241, 255);
    padding: 3rem 2rem;
    padding-top: 5rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: space-evenly;
}
.about-page-landing-section-right img{
    width: 350px;
}
.about-page-landing-section-left{
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    max-width: 500px;
}
.about-page-landing-section-left h2 span{
    color: var(--blue);
}

.about-page-second-section{
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    text-align: center;
}
.about-page-second-section h3{
    font-size: 2rem;
    width: 80%;
}
.about-page-second-section h3 span{
    color: var(--green);
}
.about-page-second-section p{
    width: 70%;
}
.about-page-second-section video{
    width: 60%;
    border: none;
}
.doctor-section{
    background-color: rgb(246, 246, 246);
    padding: 4rem 2rem;
    display: flex;
    justify-content: space-evenly;
    gap: 2rem;
    flex-wrap: wrap;
    align-items: center;
}
.doctor-section-left{
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.doctor-section-left h3{
    font-size: 1.7rem;
}
.doctor-section-left h3 span{
    color: var(--blue);
}
.doctor-section-right{
    display: flex;
    justify-content: center;
    align-items: center;
}
.doctor-section-right img{
    width: 330px;
}


@media screen and (max-width:750px) {
    .about-page-landing-section-right img{
        width: 250px;
    }
}
@media screen and (max-width:700px) {
    .about-page-landing-section{
        flex-direction: column;
        /* min-height: 0vh; */
        /* height: auto; */
        justify-content: center;
    }
    .about-page-landing-section-left{
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

@media screen and (max-width:500px) {
    .about-page-landing-section{
        min-height: 0vh;
        height: 90vh;
    }
    .doctor-section-right img{
        width: 270px;
    }
}

@media screen and (max-width:420px) {
    .about-page-second-section h3{
        font-size: 1.2rem;
    }
    .about-page-landing-section-right img{
        width: 200px;
    }
    .about-page-landing-section{
        height: 80vh;
    }
}