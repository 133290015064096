/* COVID SECTION */
.covid-section{
    width: 100%;
    padding: 6rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    text-align: center;
    background: url("../../images/covid.png");
    background-size: cover;
    background-position: center;
    color: white;
    background-attachment: fixed;
}
.covid-section p{
    max-width: 700px;
}
.covid-section-bottom{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}
.covid-section-bottom img{
    width: 100px;
}

@media screen and (max-width:500px) {
    .covid-section-bottom img{
        width: 70px;
    }
    
}