
.listed-service-card{
    padding: 1.5rem 1.5rem;
    display: flex;
    gap: 2rem;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
    border-radius: 6px;
    position: relative;
}
.listed-service-card img{
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
}
.listed-service-card-content{
    max-width: 300px;
}
.listed-service-card-content p{
    font-size: 10px;
}
.listed-service-card-content-bottom{
    display: flex;
    gap: 1rem;
    margin-top: 0.7rem;
}
.listed-service-card-content .delete-icon{
    font-size: 20px;
}
.listed-service-card-content .edit-icon{
    font-size: 20px;
}