.contact-page{
    width: 100%;
    min-height: 100vh;
}
.contact-page-landing-section{
    padding: 3rem 2rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    min-height: 100vh;
    padding-top: 6rem;
    background: url("../../images/contact-page-landing-bg.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
}
.contact-page-landing-section::before{
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.548);
}
.contact-page-landing-section-left{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}
.contact-page-landing-section-right{
    position: relative;
}
.contact-form{
    padding: 2rem 2rem;
    background-color: var(--blue);
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.contact-form .btn-a{
    background-color: white;
    color: var(--blue);
}
.contact-form h4{
    color: white;
    font-size: 1.5rem;
}
.connecting-card{
    width: 300px;
    padding: 0.9rem 0.7rem;
    background-color: white;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 1rem;
    border: 2px solid var(--blue);
}
.connecting-card-left{
    width: 40px;
    height: 40px;
    background-color: var(--blue);
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.connecting-card-right{
    display:flex;
    flex-direction: column;
    gap: 0.5rem;
}
.connecting-card-right p{
    font-size: 14px;
}
.connecting-card-icon{
    font-size: 15px;
}

@media screen and (max-width:800px) {
    .contact-page-landing-section{
        flex-direction: column-reverse;
        gap: 3rem;
        padding-top: 7rem;
    }
}

@media screen and (max-width:450px) {
    .contact-form{
        padding: 2rem 1.5rem;
    }
}