@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@500&display=swap');

*{
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}
:root{
  --green : #7ED957;
  --blue : #38B6FF;
}
html{
  font-size: 100%;
  scroll-behavior: smooth;
}
body{
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}
a{
  text-decoration: none;
  color: black;
}
.btn-a{
  padding: 0.5rem 1rem;
  border-radius: 6px;
  color: white;
  transition: 0.6s;
  cursor: pointer;
  text-align: center;
  background-color: var(--green);
  border: none;
}
.btn-a:hover{
  background-color: var(--blue);
}
.delete-btn{
  background-color: orangered;
}
.delete-btn:hover{
  background-color: red;
}
.accept-btn{
  background-color: var(--blue);
}
.accept-btn:hover{
  background-color: var(--green);
}



h1{
  /* font-family: "IBM Plex Sans", sans-serif; */
  font-size: 5rem;
}
h2{
  /* font-family: "IBM Plex Sans", sans-serif; */
  font-size: 3rem;
}
h3{
  font-size: 2.5rem;
}

.input-box{
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.input-box p{
  font-size: 12px;
}
.input-field{
  width: 300px;
  border: none;
  outline: none;
  padding: 0.4rem 1rem;
}
textarea{
  resize: none;
  height: 100px;
}

@media screen and (max-width:750px) {
    h1{
      font-size: 3rem;
    }
}

@media screen and (max-width:500px) {
  h2{
    font-size: 2rem;
  }
  h3{
    font-size: 1.5rem;
  }
  p{
    font-size: 10px;
  }
}

@media screen and (max-width:400px) {
  h1{
    font-size: 2rem;
  }
  h2{
    font-size: 1.5rem;
  }
}

.error-screen{
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 90;
}
.error-screen img{
  width: 300px;
}

.delete-icon{
  color: red;
  cursor: pointer;
}

.edit-icon{
  color: var(--blue);
  cursor: pointer;
}


.delete-screen{
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(4px);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
.delete-box{
  padding: 3rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  text-align: center;
  width: 400px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 6px;
}
.delete-box-options{
  display: flex;
  gap: 2rem;
}

.delete-screen .delete-box .success-icon{
  color: var(--green);
  font-size: 60px;
}

@media screen and (max-width:950px) {
  .error-screen{
    display: flex;
  }
}
@media screen and (max-width:450px) {
  .input-field{
      width: 270px;
  }
}