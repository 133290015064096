
.clinic-section{
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    text-align: center;
}
.clinic-section h3 span{
    color: var(--green);
}
.clinic-section p{
    font-size: 12px;
    max-width: 700px;
}
.clinic-section-middle{
    display: flex;
    justify-content: space-evenly;
    gap: 1.5rem;
}
.clinic-section-middle img{
    width: 400px;
    height:400px;
    object-fit: cover;
}
.clinic-section-bottom{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
}
.clinic-section-bottom img{
    width: 150px;
    height: 150px;
    object-fit: cover;
}

@media screen and (max-width:850px) {
    .clinic-section-middle img{
        width: 300px;
        height: 300px;
    }
}


@media screen and (max-width:650px) {
    .clinic-section-middle img{
        width: 250px;
        height: 250px;
    }
    .clinic-section-bottom img{
        width: 100px;
        height: 100px;
        object-fit: cover;
    }
}

@media screen and (max-width:550px) {
    .clinic-section-middle img{
        width: 200px;
        height: 200px;
    }
    .clinic-section-bottom img{
        width: 80px;
        height: 80px;
        object-fit: cover;
    }
}

@media screen and (max-width:450px) {
    .clinic-section-middle img{
        width: 150px;
        height: 150px;
    }
    .clinic-section-bottom img{
        width: 70px;
        height: 70px;
        object-fit: cover;
    }
}